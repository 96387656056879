import type { SlipLayoutProps } from '../types';
import { BetSlipResultState, TopLevelTabs } from '../../../enums';

const defaultSlipLayoutProps: Partial<SlipLayoutProps> = {
  activeTopTabId: TopLevelTabs.SLIP,
  slipSize: 0,
  myBetsCount: 0,
  resultState: BetSlipResultState.INITIAL,
};

export default defaultSlipLayoutProps;
