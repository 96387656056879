import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';

import type { WinBoostInfoProps } from '../types';
import { getWinBoostPercentageString } from '../../../submodules/accumulator-boost/utils';

interface UseWinBoostInfo {
  currentBoostText: ComputedRef<string>;
  nextBoostText: ComputedRef<string>;
  currentBonus: ComputedRef<string>;
  onInfoClick(): void;
}

export function useWinBoostInfo(props: WinBoostInfoProps): UseWinBoostInfo {
  const router = useRouter();
  const { $translate } = useI18n();
  const $formatMoney = useFormatMoney();

  const currentBoostTranslationsParams = computed<Record<string, string>>(() => ({ currentBoost: props.currentBoost ? getWinBoostPercentageString(props.currentBoost) : '' }));

  const currentBoostText = computed<string>(() => $translate('WEB2_WIN_BOOST_CURRENT_BOOST', currentBoostTranslationsParams).value);

  const nextBoostTranslationsParams = computed<Record<string, string>>(() => ({
    nextCount: props.itemsToNextBoost ? `${props.itemsToNextBoost}` : '',
    nextBonus: props.nextBoost ? getWinBoostPercentageString(props.nextBoost) : '',
  }));

  const fistBoostTranslationsParams = computed<Record<string, string>>(() => ({
    ...nextBoostTranslationsParams.value,
    minOdds: props.requiredOdds ?? '',
  }));

  const nextBoostText = computed<string>(() => (props.currentBoost
    ? $translate('WEB2_WIN_BOOST_NEXT_BOOST', nextBoostTranslationsParams).value
    : $translate('WEB2_WIN_BOOST_GET_FIRST_BOOST', fistBoostTranslationsParams).value));

  const currentBonus = computed<string>(() => {
    if (!props.currentWinBonus) {
      return '';
    }
    return $formatMoney(props.currentWinBonus);
  });

  const onInfoClick = (): void => {
    void router.push({ name: RouteName.SLIP_WIN_BOOST_INFO });
  };

  return {
    onInfoClick,
    currentBoostText,
    nextBoostText,
    currentBonus,
  };
}
