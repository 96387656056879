<script setup lang="ts">
import { CashoutButton } from 'web/src/components/BetCashOut/components';

import type { SlipListHistoryItemEmits, SlipListHistoryItemProps } from '../types';
import useSlipListHistoryItem from '../composables/useSlipListHistoryItem';

const props = defineProps<SlipListHistoryItemProps>();
defineEmits<SlipListHistoryItemEmits>();

const {
  computedRunnerName,
  betDetailsUrl,
} = useSlipListHistoryItem(props);
</script>

<template>
  <a v-auto-id="'SlipListHistoryItem'"
    :href="betDetailsUrl"
    :class="{
      [$style['slip-list-item']]: true,
      [$style['slip-list-item--is-done']]: true,
    }"
    @click.prevent="$emit('item-click')"
  >
    <div :class="$style['slip-list-item__wrapper-inner']">
      <div :class="$style['slip-list-item__container']">
        <div :class="$style['slip-list-item__main']">
          <div :class="$style['slip-list-item__columns']">
            <div
              :class="[
                $style['slip-list-item__column'],
                $style['slip-list-item__column--status'],
              ]"
            >
              {{ isLive ? $t('WEB2_SLIP_GAME_STARTED') : $t('WEB2_SLIP_BET_PLACED') }}
            </div>
            <div
              :class="[
                $style['slip-list-item__column'],
                $style['slip-list-item__column--date'],
              ]"
            >
              {{ eventDate }}
            </div>
          </div>
          <div :class="$style['slip-list-item__columns']">
            <div
              :class="[
                $style['slip-list-item__column'],
                $style['slip-list-item__column--left'],
              ]"
            >
              <ul
                v-if="!!competitors?.length"
                :class="$style['slip-list-item__competitors']"
              >
                <li
                  v-for="team in competitors"
                  :key="team"
                  :class="$style['slip-list-item__competitors-member']"
                >
                  {{ team }}
                </li>
              </ul>
              <p
                v-else
                :class="$style['slip-list-item__caption']"
              >
                {{ eventName }}
              </p>
              <p :class="$style['slip-list-item__market-runner']">
                <span
                  v-if="isLive"
                  :class="$style['slip-list-item__live-badge']"
                >
                  LIVE
                </span>
                <template v-if="marketName">
                  <span>{{ marketName }}</span>
                  <span :class="$style['slip-list-item__dash']">-</span>
                </template>
                <span
                  :class="$style['slip-list-item__runner-name']"
                >{{ computedRunnerName }}</span>
              </p>
            </div>
            <div
              :class="[
                $style['slip-list-item__column'],
                $style['slip-list-item__column--right'],
              ]"
            >
              <div :class="$style['slip-list-item__odds-container']">
                <p :class="$style['slip-list-item__odd']">
                  {{ odd }}
                </p>
              </div>
              <p :class="$style['slip-list-item__bet-value']">
                {{ stake }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="cashOutAvailable"
        :class="$style['slip-list-item__cash-out']"
      >
        <CashoutButton
          :cashout-amount="cashoutAmount"
          :stake-amount="stakeAmount"
          @click="$emit('cash-out-click')"
        />
      </div>
    </div>
  </a>
</template>

<style lang="scss" module>
@import 'web/src/modules/slip/components/SlipListItem/style/slip-list-item';
</style>
