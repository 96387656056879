import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { BetType } from '@leon-hub/api-sdk';

import { useI18n } from '@core/i18n';

import type { SlipListHistoryItemProps } from '../types';
import { getHistoryDetailsRoute } from '../../../utils';

interface UseSlipListHistoryItem {
  computedRunnerName: ComputedRef<string>;
  betDetailsUrl: ComputedRef<string>;
}

export default function useSlipListHistoryItem(props: SlipListHistoryItemProps): UseSlipListHistoryItem {
  const { $translate } = useI18n();
  const router = useRouter();

  const count = computed<Record<string, string>>(() => ({ COUNT: `${props.itemsQuantity ?? ''}` }));

  const computedRunnerName = computed<string>(() => {
    if (props.betType === BetType.EXPRESS) {
      return $translate('WEB2_BET_EXPRESS_MULTIPLICITY', count).value;
    }
    return props.runnerName ?? '';
  });

  const betDetailsUrl = computed<string>(() => router.resolve(getHistoryDetailsRoute(`${props.id}`)).href);

  return {
    computedRunnerName,
    betDetailsUrl,
  };
}
