import type { VInputInputEvent } from '@leon-hub/input-types';

import type { BetSlipBookingCodeFormEmits, BetSlipBookingCodeFormProps } from '../types';

interface UseBookingCodeForm {
  onSubmit(): void;
  onClear(): void;
  onInput(data: VInputInputEvent): void;
}

export function useBookingCodeForm(props: BetSlipBookingCodeFormProps, emit: BetSlipBookingCodeFormEmits): UseBookingCodeForm {
  const onInput = (data: VInputInputEvent): void => {
    const nextValue = data.target.value
      .trim()
      .toUpperCase();
    emit('input-code', nextValue);
  };

  const onClear = (): void => {
    emit('input-code', '');
  };

  const onSubmit = (): void => {
    if (!props.error) {
      emit('submit-code');
    }
  };

  return {
    onInput,
    onClear,
    onSubmit,
  };
}
