import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue';

import { SlipTypeId } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { Timer } from '@leon-hub/utils';

import { useI18n } from '@core/i18n';

import type {
  SlipListBettingItemEmits,
  SlipListBettingItemProps,
} from '../types';
import { SlipListBettingItemStatus } from '../enums';

interface UseSlipListBettingItem {
  showBankerButton: ComputedRef<boolean>;
  showErrorBlock: ComputedRef<boolean>;
  isUnavailableState: ComputedRef<boolean>;
  isPaused: ComputedRef<boolean>;
  isBlocked: ComputedRef<boolean>;
  computedErrorMessage: ComputedRef<string>;
  wrapperRef: Ref<HTMLDivElement | undefined>;
  isCollapseAnimationStarted: Ref<boolean>;
  highlightedReplacedItem: Ref<boolean>;
  emitBankerClick(): void;
  onDeleteAnimationEnd(): void;
  onDeleteButtonClick(): void;
}

export default function useSlipListBettingItem(
  props: SlipListBettingItemProps,
  emit: SlipListBettingItemEmits,
): UseSlipListBettingItem {
  const { $translate } = useI18n();

  const bus = useEventsBus();

  const isUnavailableState = computed<boolean>(() => props.status !== SlipListBettingItemStatus.DEFAULT);

  const isBlocked = computed<boolean>(
    () => props.status === SlipListBettingItemStatus.BLOCKED || props.status === SlipListBettingItemStatus.LIMIT,
  );
  const isPaused = computed<boolean>(() => props.status === SlipListBettingItemStatus.PAUSED);

  const showBankerButton = computed<boolean>(
    () => !!(props.betMode === SlipTypeId.SYSTEM && props.bankersAvailable && !isUnavailableState.value),
  );

  const showErrorBlock = computed<boolean>(() => isBlocked.value || !!props.errorMessage);

  const computedErrorMessage = computed<string>(() => {
    switch (props.status) {
      case SlipListBettingItemStatus.BLOCKED:
        return $translate('JS_SLIP_UNAVAILABLE_MARKET').value;
      case SlipListBettingItemStatus.LIMIT:
        return $translate('WEB2_EVENT_CARD_LIMIT_WARNING').value;
      case SlipListBettingItemStatus.PAUSED:
        return '';
      default:
        return props.errorMessage && !props.errorMessageHidden && !(isPaused.value && isBlocked.value)
          ? props.errorMessage
          : '';
    }
  });

  const wrapperRef = ref<HTMLDivElement | undefined>();

  const isCollapseAnimationStarted = ref<boolean>(false);

  const emitDeleteClick = (): void => {
    emit('delete-click');
  };

  const emitBankerClick = (): void => {
    emit('banker-click');
  };

  const onDeleteAnimationEnd = (): void => {
    if (isCollapseAnimationStarted.value) {
      emitDeleteClick();
    }
  };

  const onDeleteButtonClick = (): void => {
    if (!props.showDeleteAnimation || !wrapperRef.value) {
      emitDeleteClick();
      return;
    }
    wrapperRef.value.style.setProperty('--initialHeight', `${wrapperRef.value.offsetHeight}px`);
    isCollapseAnimationStarted.value = true;
    bus.emit(BusEvent.SLIP_ENTRY_DELETE_CLICK, {});
    /** @see onDeleteAnimationEnd */
  };

  const highlightedReplacedItem = ref<boolean>(false);

  let highlightedReplacedItemTimeout = 0;

  watch(() => props.replacedAt, (value) => {
    if (value) {
      highlightedReplacedItem.value = true;
      Timer.clearTimeout(highlightedReplacedItemTimeout);
      highlightedReplacedItemTimeout = Timer.setTimeout(() => {
        highlightedReplacedItem.value = false;
      }, props.highlightTimeout || 3000);
    }
  }, { immediate: true });

  onBeforeUnmount(() => {
    Timer.clearTimeout(highlightedReplacedItemTimeout);
  });

  return {
    showBankerButton,
    showErrorBlock,
    computedErrorMessage,
    wrapperRef,
    isCollapseAnimationStarted,
    isUnavailableState,
    isPaused,
    isBlocked,
    highlightedReplacedItem,
    emitBankerClick,
    onDeleteAnimationEnd,
    onDeleteButtonClick,
  };
}
