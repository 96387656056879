<script setup lang="ts">
import type { ConfirmDialog } from '@core/dialogs';

import { ModalWidth } from '@components/dialogs';

import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';

import type { BetSlipTraceEmits, BetSlipTraceProps } from '../types';
import useBetSlipTrace from '../composables/useBetSlipTrace';
import BetSlipTraceContent from './BetSlipTraceContent.vue';

const props = withDefaults(defineProps<BetSlipTraceProps>(), {
  lines: () => [],
});
defineEmits<BetSlipTraceEmits>();
const { formattedLines } = useBetSlipTrace(props);

const modal: ConfirmDialog = {
  width: ModalWidth.DEFAULT,
  buttons: [],
};
</script>

<template>
  <ConfirmModal v-auto-id="'BetSlipTrace'"
    :class="$style['slip-trace-modal']"
    :modal="modal"
    is-alert
    @close="$emit('close-modal')"
  >
    <div :class="$style['bet-slip-trace']">
      <div :class="$style['bet-slip-trace__heading']">
        Slip trace
      </div>
      <div :class="$style['bet-slip-trace__content']">
        <!-- //NOSONAR --><table :class="$style['bet-slip-trace__table']">
          <tbody>
            <tr
              v-for="(line, index) in formattedLines"
              :key="index"
              :class="{
                [$style['bet-slip-trace__row']]: true,
                [$style['bet-slip-trace__row--error']]: line.error,
              }"
            >
              <td
                :class="[
                  $style['bet-slip-trace__cell'],
                  $style['bet-slip-trace__cell--time'],
                ]"
              >
                {{ line.formattedTime }}
              </td>
              <td
                :class="[
                  $style['bet-slip-trace__cell'],
                  $style['bet-slip-trace__cell--content'],
                ]"
              >
                <BetSlipTraceContent :contents="line.contents" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ConfirmModal>
</template>

<style lang="scss" module>
.bet-slip-trace {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  color: var(--TextPrimary);
  text-align: left;
  cursor: auto;
  user-select: auto;

  &__heading {
    flex: none;
    padding: 0 5px;
    margin: 0 0 10px;
    font-size: 150%;
  }

  &__content {
    flex: 1;
    padding-right: 20px;
    margin-right: -20px;
    overflow-y: auto;
  }

  &__table {
    width: 100%;
    line-height: 1.3;
    table-layout: fixed;
    border-spacing: 0;
  }

  &__row {
    font-weight: normal;

    &--error {
      color: var(--ErrorText);
    }
  }

  &__cell {
    padding: 3px 5px;
    vertical-align: top;

    &--time {
      width: 80px;
    }

    &--content {
      white-space: pre;
    }
  }
}
</style>
