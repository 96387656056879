<script setup lang="ts">
import { ButtonKind, VButton } from '@components/buttons';

import type { BookingCodeLoadResultEmits, BookingCodeLoadResultProps } from '../types';
import { useBookingCodeLoadResult } from '../composables/useBookingCodeLoadResult';

const props = defineProps<BookingCodeLoadResultProps>();
const emits = defineEmits<BookingCodeLoadResultEmits>();

const {
  logoUrl,
  buttonText,
  onButtonClick,
} = useBookingCodeLoadResult(props, emits);
</script>

<template>
  <div v-auto-id="'BookingCodeLoadResult'" :class="$style['booking-code-load-result']">
    <div>
      <div :class="$style['booking-code-load-result__logo']">
        <img
          :src="logoUrl"
          alt="logo"
          :class="$style['booking-code-load-result__img']"
        >
      </div>
      <div :class="$style['booking-code-load-result__header']">
        {{ isAllEventsExpired ? $t('WEB2_BOOKING_CODE_ALL_EXPIRED_CAPTION') : $t('WEB2_BOOKING_CODE_CERTAIN_EXPIRED_CAPTION') }}
      </div>
      <div :class="$style['booking-code-load-result__text']">
        <template v-if="isAllEventsExpired">
          <div>{{ $t('WEB2_BOOKING_CODE_ALL_EXPIRED_DESCRIPTION') }}</div>
          <div>{{ $t('WEB2_BOOKING_CODE_ALL_EXPIRED_LOGIN') }}</div>
        </template>
        <template v-else>
          {{ isFromLink ? $t('WEB2_BOOKING_CODE_CERTAIN_EXPIRED_FROM_LINK') : $t('WEB2_BOOKING_CODE_CERTAIN_EXPIRED_FROM_CODE') }}
        </template>
      </div>
    </div>
    <div :class="$style['booking-code-load-result__button']">
      <VButton
        :label="buttonText"
        :kind="ButtonKind.PRIMARY"
        full-width
        @click="onButtonClick"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .booking-code-load-result {
    padding: 34px 16px 40px;
    text-align: center;

    @if not ($isDesktop) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: var(--Layer1);
    }

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 18px;
    }

    &__img {
      display: block;
      width: auto;
      height: 68px;
      object-fit: contain;
    }

    &__header {
      @include medium\18\28;

      margin: 0 0 8px;
      color: var(--TextDefault);
    }

    &__text {
      @include regular\14\24\025;

      color: var(--TextPrimary);
    }

    &__button {
      margin: 42px 0 0;
    }
  }
}
</style>
