<script setup lang="ts">
import { useWinBoostInfoPage } from '../composable';

const {
  descriptionText,
  tableRows,
} = useWinBoostInfoPage();
</script>

<template>
  <div v-auto-id="'WinBoostInfoRoutePage'" :class="$style['win-boost-info']">
    <p :class="$style['win-boost-info__description']">
      {{ descriptionText }}
    </p>
    <div :class="$style['win-boost-info__table-background']">
      <table :class="$style['win-boost-table']">
        <caption :class="$style['win-boost-table__caption']">
          {{ $t('WEB2_WIN_BOOST_INFO_TABLE_CAPTION') }}
        </caption>
        <colgroup>
          <col :class="$style['win-boost-table__col-count']">
          <col :class="$style['win-boost-table__col-boost']">
        </colgroup>
        <thead :class="$style['win-boost-table__head']">
          <tr :class="$style['win-boost-table__row']">
            <th
              :class="[
                $style['win-boost-table__cell'],
                $style['win-boost-table__cell--count'],
              ]"
            >
              {{ $t('WEB2_WIN_BOOST_INFO_TABLE_COUNT') }}
            </th>
            <th
              :class="[
                $style['win-boost-table__cell'],
                $style['win-boost-table__cell--boost'],
              ]"
            >
              {{ $t('WEB2_WIN_BOOST_INFO_TABLE_BOOST') }}
            </th>
          </tr>
        </thead>
        <tbody :class="$style['win-boost-table__body']">
          <tr
            v-for="(row, index) in tableRows"
            :key="index"
            :class="$style['win-boost-table__row']"
          >
            <td
              :class="[
                $style['win-boost-table__cell'],
                $style['win-boost-table__cell--count'],
              ]"
            >
              {{ row.count }}
            </td>
            <td
              :class="[
                $style['win-boost-table__cell'],
                $style['win-boost-table__cell--boost'],
              ]"
            >
              {{ row.boost }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style module lang="scss">
.win-boost-info {
  padding: 16px 32px 32px;
  color: var(--TextPrimary);

  &__description {
    @include regular\14\24\025;

    padding: 0;
    margin: 0;
  }

  &__table-background {
    padding: 12px;
    margin-top: 20px;
    background: var(--Layer1);
    border-radius: 5px;
  }
}

.win-boost-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: none;

  &__caption {
    @include medium\13\16;

    padding: 0;
    margin: 0 0 12px;
    text-align: left;
  }

  &__row {
    border-bottom: 2px solid var(--Layer1);
  }

  &__col-count {
    text-align: left;
  }

  &__col-boost {
    width: 60%;
    text-align: right;
  }

  &__cell {
    @include regular\14\20\025;

    padding: 12px;
    background: var(--Layer2);

    &--count {
      text-align: left;
    }

    &--boost {
      text-align: center;
    }
  }

  &__head &__cell--count {
    border-radius: 5px 0 0;
  }

  &__head &__cell--boost {
    border-radius: 0 5px 0 0;
  }

  &__body &__row:last-child {
    border-bottom: none;
  }

  &__body &__row:last-child &__cell--count {
    border-radius: 0 0 0 5px;
  }

  &__body &__row:last-child &__cell--boost {
    border-radius: 0 0 5px;
  }
}
</style>
