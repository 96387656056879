<script setup lang="ts">
import { provide } from 'vue';

import { FormProvidable } from '@leon-hub/form-utils';

import { ButtonKind, ButtonType, VButton } from '@components/buttons';

import { TextInput } from 'web/src/components/Input';

import type { BetSlipBookingCodeFormEmits, BetSlipBookingCodeFormProps } from './types';
import { useBookingCodeForm } from './composables/useBookingCodeForm';

const props = defineProps<BetSlipBookingCodeFormProps>();
const emits = defineEmits<BetSlipBookingCodeFormEmits>();

provide(FormProvidable.FormContext, { isFromModal: true });

const {
  onSubmit,
  onInput,
  onClear,
} = useBookingCodeForm(props, emits);
</script>

<template>
  <form v-auto-id="'BetSlipBookingCodeForm'"
    :class="$style['booking-code-form']"
    @submit.stop.prevent="onSubmit"
  >
    <TextInput
      :label="$t('WEB2_BET_SHARE_BOOKING_CODE')"
      :placeholder="placeholder"
      :error="error"
      :value="value"
      regexp="^[0-9a-zA-Z\s]*$"
      @input="onInput"
      @clear="onClear"
    />
    <div :class="$style['booking-code-form__footer']">
      <VButton
        :disabled="!value"
        :type="ButtonType.SUBMIT"
        :label="$t('WEB2_BET_SHARE_LOAD_SLIP')"
        :kind="ButtonKind.PRIMARY"
        :is-loading="isPending"
        full-width
        @click="onSubmit"
      />
    </div>
  </form>
</template>

<style module lang="scss">
  .booking-code-form {
    margin: 30px 0 0;

    &:not(:last-child) {
      margin-bottom: -10px;
    }

    &__footer {
      margin: 8px 0 0;
    }
  }
</style>
