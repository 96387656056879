<script setup lang="ts">
import { priceChangePolicyDenyChanges } from '@leon-hub/api-sdk';

import { VSwitch } from '@components/switch';

import type { SlipCommonSettingsEmits, SlipCommonSettingsProps } from './types';
import useSlipCommonSettings from './composables/useSlipCommonSettings';

const props = withDefaults(defineProps<SlipCommonSettingsProps>(), {
  priceChangePolicy: priceChangePolicyDenyChanges,
  totalHandicapPriceChangePolicy: priceChangePolicyDenyChanges,
});

const emits = defineEmits<SlipCommonSettingsEmits>();

const {
  autoUpdateEnabled,
  onlyIncrease,
  acceptsTotalHandicapChanges,
  onAutoUpdateChange,
  toggleOnlyIncrease,
  resetOnlyIncreaseValue,
  onTotalHandicapSwitch,
} = useSlipCommonSettings(props, emits);
</script>

<template>
  <div v-auto-id="'SlipCommonSettings'" :class="$style['slip-common-settings']">
    <div :class="$style['slip-common-settings__group']">
      <div :class="$style['slip-common-settings__checkbox']">
        <VSwitch
          v-data-test="{ el: 'price-change-policy-main-switch', value: autoUpdateEnabled }"
          :checked="autoUpdateEnabled"
          :label="$t('JS_PRICE_CHANGE_POLICY_CHECKBOX')"
          @change="onAutoUpdateChange"
        />
      </div>
      <p :class="$style['slip-common-settings__description']">
        {{ $t('JS_SLIP_BET_SETTING_PCP_INFO') }}
      </p>
      <div
        :class="{
          [$style['slip-common-settings__collapsible']]: true,
          [$style['slip-common-settings__collapsible--collapsed']]: !autoUpdateEnabled,
        }"
        @transitionend="resetOnlyIncreaseValue"
      >
        <div :class="$style['slip-common-settings__checkbox']">
          <VSwitch
            v-data-test="{ el: 'price-change-policy-only-increase-switch', value: onlyIncrease }"
            :checked="onlyIncrease"
            :label="$t('WEB2_PRICE_CHANGE_POLICY_ALLOW_INCREASE')"
            @change="toggleOnlyIncrease"
          />
        </div>
      </div>
    </div>
    <div
      v-if="totalHandicapReplaceEnabled"
      :class="$style['slip-common-settings__group']"
    >
      <div :class="$style['slip-common-settings__checkbox']">
        <VSwitch
          v-data-test="{ el: 'accept-total-handicap-change-switch', value: acceptsTotalHandicapChanges }"
          :checked="acceptsTotalHandicapChanges"
          :label="$t('WEB2_SLIP_AUTCONFIRM_TOTAL_HANDICAP_SWITCH')"
          @change="onTotalHandicapSwitch"
        />
      </div>
      <div :class="$style['slip-common-settings__description']">
        {{ $t('WEB2_SLIP_AUTCONFIRM_TOTAL_HANDICAP_DESCRIPTION') }}
      </div>
    </div>
    <div
      v-if="null"
      :class="$style['slip-common-settings__group']"
    >
      <div :class="$style['slip-common-settings__checkbox']">
        <VSwitch
          v-data-test="{ el: 'show-slip-on-first-added-switch', value: !showSlipOnFirstAdded }"
          :checked="!showSlipOnFirstAdded"
          :label="$t('WEB2_SETTINGS_SHOW_SLIP_CHECKBOX')"
          @change="$emit('toggle-show-slip-on-first-added')"
        />
      </div>
      <div :class="$style['slip-common-settings__description']">
        {{ $t('WEB2_SETTINGS_SHOW_SLIP_CHECKBOX_INFO') }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.slip-common-settings {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;

  &__group {
    padding: 16px;
    background: var(--Layer2);
    border-radius: 10px;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__checkbox {
    padding: 8px 0;
  }

  &__collapsible {
    max-height: 50px;
    overflow: hidden;
    opacity: 1;
    transition: 0.3s ease-out;
    transition-property: opacity, max-height;

    &--collapsed {
      max-height: 0;
      opacity: 0;
    }
  }

  &__description {
    @include regular\14\20\025;

    padding: 0;
    margin: 0 0 10px;
    color: var(--TextSecondary);
  }
}
</style>
