import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@core/i18n';

import { getMascotLogoByName } from '@components/loader';

import type { BookingCodeLoadResultEmits, BookingCodeLoadResultProps } from '../types';

interface UseBookingCodeLoadResult {
  buttonText: ComputedRef<string>;
  logoUrl: ComputedRef<string>;
  onButtonClick(): void;
}

export function useBookingCodeLoadResult(props: BookingCodeLoadResultProps, emit: BookingCodeLoadResultEmits): UseBookingCodeLoadResult {
  const { $translate } = useI18n();

  const logoUrl = computed<string>(() => {
    let name = 'mascot';
    if (props.isLightTheme) {
      name += '--light';
    }

    return getMascotLogoByName(name);
  });

  const buttonText = computed<string>(() => {
    if (props.isAllEventsExpired) {
      return props.isLoggedIn
        ? $translate('WEB2_BOOKING_CODE_TO_HOMEPAGE').value
        : $translate('WEB2_DO_LOGIN').value;
    }
    return $translate('WEB2_BOOKING_CODE_TO_SLIP').value;
  });

  const onButtonClick = (): void => emit('button-click');

  return {
    logoUrl,
    buttonText,
    onButtonClick,
  };
}
