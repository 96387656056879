import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { BaseInputEmits, VInputInputEvent } from '@leon-hub/input-types';
import { assert } from '@leon-hub/guards';
import { IconName, IconSize } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { VIconProps } from '@components/v-icon';

import { useBaseInputEvents } from 'web/src/components/Input/composables';

import type { BetsSlipSelectProps } from '../types';
import isFreeBetOption from '../../../guards/isFreeBetOption';

interface UseBetSlipSelect {
  isFocused: Ref<boolean>;
  isSingleValue: ComputedRef<boolean>;
  placeHolderText: ComputedRef<string>;
  displayLabel: ComputedRef<string>;
  displayValue: ComputedRef<string>;
  arrowIconProps: ComputedRef<VIconProps>;
  setFocused(): void;
  returnFocus(): void;
  selectChange(event: Event): void;
  emitChange(event: VInputInputEvent): void;
}

export default function useBetSlipSelect(
  props: BetsSlipSelectProps,
  emits: BaseInputEmits,
): UseBetSlipSelect {
  const { $translate } = useI18n();
  const { emitChange } = useBaseInputEvents(emits);

  const isFocused = ref<boolean>(false);

  const setFocused = (): void => {
    isFocused.value = true;
  };

  const returnFocus = (): void => {
    isFocused.value = false;
  };

  const isSingleValue = computed<boolean>(() => props.options?.length === 1);

  const placeHolderText = computed<string>(() => (props.isFreebet ? $translate('WEB2_CHOOSE_YOUR_BONUS').value : ''));

  const displayLabel = computed<string>(() => {
    const matchedLabel = props.options?.find((option) => option.value === props.selectedValue);
    return matchedLabel?.label || placeHolderText.value;
  });

  const displayValue = computed<string>(() => {
    if (!props.isFreebet) {
      return '';
    }
    const matchedValue = props.options?.find((option) => option.value === props.selectedValue);
    if (!matchedValue) {
      return '';
    }
    assert(isFreeBetOption(matchedValue));
    return matchedValue?.freeBetAmount || '';
  });

  const arrowIconProps = computed<VIconProps>(() => ({
    name: isSingleValue.value ? IconName.LOCK : IconName.TRIANGLE_DOWN,
    size: IconSize.SIZE_16,
  }));

  const selectChange = (event: Event): void => {
    const { target } = event;
    assert(target instanceof HTMLSelectElement);
    if (!target || target.value === props.selectedValue) {
      return;
    }
    const payload: VInputInputEvent = {
      target: {
        name: props.name || '',
        value: target.value,
      },
    };
    emitChange(payload);
  };

  return {
    isFocused,
    isSingleValue,
    placeHolderText,
    displayLabel,
    displayValue,
    arrowIconProps,
    setFocused,
    returnFocus,
    selectChange,
    emitChange,
  };
}
