import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { VInputInputEvent } from '@leon-hub/input-types';
import { isNumber } from '@leon-hub/guards';

import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';

import type { NumberStringInputProps } from 'web/src/components/Input';
import type { BetSlipEditFastBetItem } from 'web/src/modules/fast-bets/types';
import { BetInputErrorKind } from 'web/src/modules/fast-bets/enums';

import type {
  EditFastBetEmits,
  EditFastBetProps,
  GetErrorMessagePayload,
} from '../types';

interface UseBetSlipEditFastBet {
  standardSumProps: ComputedRef<NumberStringInputProps>;
  computedItems: ComputedRef<NumberStringInputProps[]>;
  onFastBetsBlur(event: VInputInputEvent, index: number): void;
  onFastBetsInput(event: VInputInputEvent, index: number): void;
  onStandardSumInput(event: VInputInputEvent): void;
  onSwitchMaxEnabled(value: boolean): void;
}

export default function useBetSlipEditFastBet(
  props: EditFastBetProps,
  emit: EditFastBetEmits,
): UseBetSlipEditFastBet {
  const { $translate } = useI18n();

  const formatMoney = useFormatMoney();

  const getErrorMessage = ({ error, maxValue, minValue }: GetErrorMessagePayload): string => {
    switch (error) {
      case BetInputErrorKind.INVALID_INPUT:
        return $translate('WEB2_FAST_BET_INCORRECT_INPUT').value;
      case BetInputErrorKind.ABOVE_MAXIMUM:
        return `${$translate('WEB2_FAST_BET_ABOVE_MAX').value} ${formatMoney(maxValue || 0)}`;
      case BetInputErrorKind.BELOW_MINIMUM:
        return `${$translate('WEB2_FAST_BET_BELOW_MIN').value} ${formatMoney(minValue || 0)}`;
      default:
        return '';
    }
  };

  const getLimitsLabel = (min: number, max?: number | null): string => {
    const from = $translate('WEB2_FROM').value;
    const till = $translate('JSPBET_TILL').value;
    const minLabel = formatMoney(min, { hideCurrency: !!max });
    const maxLabel = max ? ` ${till} ${formatMoney(max)}` : '';
    return `${from} ${minLabel}${maxLabel}`;
  };

  const standardSumProps = computed<NumberStringInputProps>(() => ({
    name: 'standardSum',
    label: $translate('WEB2_SLIP_SETTINGS_STANDARD_SUM_LABEL').value,
    value: isNumber(props.standardBetAmount) ? `${props.standardBetAmount}` : '',
    error: props.standardBetInputErrorKind
      ? getErrorMessage({
          error: props.standardBetInputErrorKind,
          minValue: props.minStandardBet,
          maxValue: props.maxStandardBet,
        })
      : undefined,
    isSaved: props.standardBetAmountIsSaved,
    placeholder: $translate('WEB2_SLIP_SETTINGS_STANDARD_SUM_PLACEHOLDER').value,
    hint: getLimitsLabel(props.minStandardBet, props.maxStandardBet),
    showHintOnFocus: true,
  }));

  const computedItems = computed<NumberStringInputProps[]>(() => {
    const translatedLabel = $translate('WEB2_BETSLIP_FAST_SUMM_LABEL').value;
    return (props.fastBets ?? []).map(({ value, error, changed }: BetSlipEditFastBetItem, index) => ({
      value,
      error: error ? getErrorMessage({ error, maxValue: props.maxFastBet, minValue: props.minFastBet }) : '',
      isSaved: changed && props.changesIsSaved,
      label: `${translatedLabel} ${index + 1}`,
      hint: getLimitsLabel(props.minFastBet, props.maxFastBet),
      name: `fastBet${index}`,
      showHintOnFocus: true,
    }));
  });

  const currentValues = computed<string[]>(() => (props.fastBets ?? []).map(({ value }) => value));

  const getUpdatedValues = (event: VInputInputEvent, index: number): string[] => {
    const { target } = event;
    const { value } = target;
    const valuesCopy = [...currentValues.value];

    valuesCopy[index] = value;
    return valuesCopy;
  };

  const onFastBetsBlur = (event: VInputInputEvent, index: number): void => {
    emit('fast-bets-blur', getUpdatedValues(event, index));
  };

  const onFastBetsInput = (event: VInputInputEvent, index: number): void => {
    emit('fast-bets-input', getUpdatedValues(event, index));
  };

  const onStandardSumInput = (event: VInputInputEvent): void => {
    emit('standard-sum-input', event.target.value);
  };

  const onSwitchMaxEnabled = (value: boolean): void => {
    emit('switch-max-enabled', value);
  };

  return {
    standardSumProps,
    computedItems,
    onFastBetsBlur,
    onFastBetsInput,
    onStandardSumInput,
    onSwitchMaxEnabled,
  };
}
