<script setup lang="ts">
import { ButtonHeight, VButton } from '@components/buttons';

import type { BetSlipPlaceBetButtonEmits, BetSlipPlaceBetButtonProps } from './types';
import { BetSlipMainButtonMode } from '../../enums';
import useBetSlipPlaceBetButton from './composables/useBetSlipPlaceBetButton';

const props = withDefaults(defineProps<BetSlipPlaceBetButtonProps>(), {
  mode: BetSlipMainButtonMode.READY_TO_PLACE_BET,
  multiSinglesTotalPrice: 0,
});

const emits = defineEmits<BetSlipPlaceBetButtonEmits>();

const {
  emitClick,
  isLoading,
  buttonKind,
  iconName,
  label,
} = useBetSlipPlaceBetButton(props, emits);
</script>

<template>
  <VButton v-auto-id="'BetSlipPlaceBetButton'"
    :label="label"
    :is-loading="isLoading"
    :kind="buttonKind"
    :disabled="disabled"
    :icon-name="iconName"
    :height="ButtonHeight.LARGE"
    full-width
    @click="emitClick"
  />
</template>
