<script setup lang="ts">
import { VSwitch } from '@components/switch';

import { NumberStringInput } from 'web/src/components/Input/components';

import type { EditFastBetEmits, EditFastBetProps } from './types';
import useBetSlipEditFastBet from './composables/useBetSlipEditFastBet';

const props = withDefaults(defineProps<EditFastBetProps>(), {
  fastBets: () => [],
});

const emit = defineEmits<EditFastBetEmits>();

const {
  standardSumProps,
  computedItems,
  onFastBetsBlur,
  onFastBetsInput,
  onStandardSumInput,
  onSwitchMaxEnabled,
} = useBetSlipEditFastBet(props, emit);
</script>

<template>
  <div v-auto-id="'BetSlipEditFastBet'" :class="$style['edit-fast-bet']">
    <div :class="$style['edit-fast-bet__default-sum']">
      <div :class="$style['edit-fast-bet__checkbox']">
        <VSwitch
          :checked="useStandardBet"
          :label="$t('WEB2_SLIP_SETTINGS_STANDARD_BET_CHECKBOX')"
          @change="$emit('toggle-use-standard-bet')"
        />
        <div
          v-if="!useStandardBet"
          :class="$style['edit-fast-bet__default-sum-info']"
        >
          {{ $t('WEB2_SLIP_SETTINGS_STANDARD_BET_INFO') }}
        </div>
        <form
          v-else
          :class="$style['edit-fast-bet__default-sum-form']"
          @submit.prevent="$emit('standard-sum-blur')"
        >
          <NumberStringInput
            v-bind="standardSumProps"
            is-slip-style
            @focus="$emit('standard-sum-focus')"
            @blur="$emit('standard-sum-blur')"
            @input="onStandardSumInput"
            @change="onStandardSumInput"
          />
        </form>
      </div>
    </div>
    <form
      :class="$style['edit-fast-bet__form']"
      @submit.prevent="$emit('fast-bets-submit')"
    >
      <p
        v-if="computedItems.length"
        :class="$style['edit-fast-bet__caption']"
      >
        {{ $t('WEB2_SLIP_SETTINGS_FAST_BETS_CAPTION') }}
      </p>
      <div
        v-for="(item, index) in computedItems"
        :key="item.name"
        :class="$style['edit-fast-bet__row']"
      >
        <NumberStringInput
          v-bind="item"
          is-slip-style
          @blur="onFastBetsBlur($event, index)"
          @input="onFastBetsInput($event, index)"
          @change="onFastBetsInput($event, index)"
        />
      </div>
      <div :class="$style['edit-fast-bet__toggle']">
        <VSwitch
          :checked="maxEnabled"
          :label="`${$t('WEB2_FAST_BET_SWITCH_LABEL')} MAX`"
          :class="$style['edit-fast-bet__switch']"
          name="showMaxSwitch"
          @change="onSwitchMaxEnabled"
        />
        <div
          :class="$style['edit-fast-bet__switch-hint']"
        >
          <span :class="$style['edit-fast-bet__max']">MAX</span> {{ $t('WEB2_FAST_BET_SWITCH_HINT') }}
        </div>
      </div>
      <input
        type="submit"
        hidden
      >
    </form>
  </div>
</template>

<style lang="scss" module>
.edit-fast-bet {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;

  &__caption {
    @include medium\16\20\025;

    padding: 16px 0;
    margin: 0;
    color: var(--TextDefault);
  }

  &__default-sum {
    padding: 10px 0;
  }

  &__default-sum-info {
    @include regular\14\20\025;

    margin: 8px 0 0;
    color: var(--TextSecondary);
  }

  &__default-sum-form {
    margin: 10px 0 0;
  }

  &__switch {
    padding: 10px 0;
  }

  &__switch-hint {
    @include regular\14\24\025;

    margin: 0 0 10px;
    color: var(--TextPrimary);
  }

  &__max {
    color: var(--TextDefault);
  }

  &__toggle {
    margin-top: 2px;
  }
}
</style>
