import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { SlipTypeId } from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';

import { useI18n } from '@core/i18n';

import type { TabsLabelItem } from 'web/src/components/Tabs';

import type { MultisingleInputPayload, SinglesKeyBoardInputPayload } from '../../../submodules/multi-singles/types';
import type { SlipEntryId } from '../../../types';
import type { BetSlipMainEmits, BetSlipMainProps } from '../types';
import { KeyboardInputType } from '../../../enums';
import isSlipTypeId from '../../../guards/isSlipTypeId';
import { getKeyboardPayloadFromEvent } from '../../../submodules/stake-input/utils';

interface UseBetSlipMain {
  betModeTabs: ComputedRef<TabsLabelItem[]>;
  showDeleteAnimation: ComputedRef<boolean>;
  selectBetMode(id: string): void;
  onEventDeleteClick(id: SlipEntryId): void;
  onEventBankerClick(id: SlipEntryId): void;
  onTraceClick(index?: number): void;
  showInputBlock: ComputedRef<boolean>;
  onStakeInput(value: string, id: SlipEntryId): void;
  onStakeInputFocus(id: SlipEntryId): void;
  onStakeInputBlur(id: SlipEntryId): void;
  onEventAcceptChanges(id: SlipEntryId): void;
  onBackspaceInput(id: SlipEntryId): void;
  onNumberInput(value: string, id: SlipEntryId): void;
  onDecimalInput(id: SlipEntryId): void;
  selectFastBet(value: number, id: SlipEntryId): void;
  onEditFastBets(): void;
  onAppear(): void;
  onDisappear(): void;
}

export default function useBetSlipMain(
  props: BetSlipMainProps,
  emit: BetSlipMainEmits,
): UseBetSlipMain {
  const { $translate } = useI18n();
  const betModeTabs = computed<TabsLabelItem[]>(() => [
    {
      id: SlipTypeId.SINGLE,
      label: $translate('WEB2_SLIP_BET_MODE_SINGLE').value,
      disabled: props.singleModeDisabled,
    },
    {
      id: SlipTypeId.EXPRESS,
      label: $translate('JSPNAV_BETSLIP_TYPE2').value,
      disabled: props.expressModeDisabled,
    },
    {
      id: SlipTypeId.SYSTEM,
      label: $translate('JSPNAV_BETSLIP_TYPE5').value,
      disabled: props.systemModeDisabled,
    },
  ]);

  const showDeleteAnimation = computed<boolean>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return false;
    }
    return !!(props.betEvents?.length && props.betEvents.length > 1);
  });

  const onStakeInputBlur = (id: SlipEntryId): void => {
    emit('stake-input-blur', id);
  };

  const clearMobileFocus = (): void => {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      if (props.focusedItemId) {
        onStakeInputBlur(props.focusedItemId);
      }
    }
  };

  const selectBetMode = (id: string): void => {
    clearMobileFocus();
    assert(isSlipTypeId(id));
    emit('select-bet-mode', id);
  };

  const onEventDeleteClick = (id: SlipEntryId): void => {
    clearMobileFocus();
    emit('event-delete-click', id);
  };

  const onEventBankerClick = (id: SlipEntryId): void => {
    emit('event-banker-click', id);
  };

  const onTraceClick = (index = 0): void => {
    emit('trace-click', index);
  };

  const showInputBlock = computed<boolean>(
    () => !!(props.isMultiSinglesMode && !props.sameStakeForSingles),
  );

  const emitStakeInput = (payload: MultisingleInputPayload): void => {
    emit('stake-input', payload);
  };

  const onStakeInput = (value: string, id: SlipEntryId): void => {
    emitStakeInput({ value, id });
  };

  const onStakeInputFocus = (id: SlipEntryId): void => {
    clearMobileFocus();
    emit('stake-input-focus', id);
  };

  const onEventAcceptChanges = (id: SlipEntryId): void => {
    clearMobileFocus();
    emit('event-accept-changes', id);
  };

  const emitSinglesMobileInput = (payload: SinglesKeyBoardInputPayload): void => {
    emit('singles-mobile-input', payload);
  };

  const onBackspaceInput = (id: SlipEntryId): void => {
    emitSinglesMobileInput({
      id,
      action: KeyboardInputType.BACKSPACE,
    });
  };

  const onNumberInput = (value: string, id: SlipEntryId): void => {
    emitSinglesMobileInput({
      id,
      value,
      action: KeyboardInputType.NUMBER,
    });
  };

  const onDecimalInput = (id: SlipEntryId): void => {
    emitSinglesMobileInput({
      id,
      action: KeyboardInputType.DECIMAL,
    });
  };

  const handleKeyboardStakeInput = (event: KeyboardEvent): void => {
    if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
      const payload = getKeyboardPayloadFromEvent(event, !!props.focusedItemId);
      if (payload && props.focusedItemId) {
        emitSinglesMobileInput({
          action: payload.action,
          id: props.focusedItemId,
          value: payload.value,
        });
      }
    }
  };

  const onAppear = (): void => {
    if (process.env.VUE_APP_RENDERING_CSR && !process.env.VUE_APP_LAYOUT_DESKTOP) {
      document.addEventListener('keydown', handleKeyboardStakeInput);
    }
  };

  const onDisappear = (): void => {
    if (process.env.VUE_APP_RENDERING_CSR && !process.env.VUE_APP_LAYOUT_DESKTOP) {
      document.removeEventListener('keydown', handleKeyboardStakeInput);
    }
  };

  const selectFastBet = (value: number, id: SlipEntryId): void => {
    emitStakeInput({ value: `${value}`, id, isFastBetValue: true });
  };

  const onEditFastBets = (): void => {
    emit('edit-fast-bets');
  };

  return {
    betModeTabs,
    showDeleteAnimation,
    selectBetMode,
    onEventDeleteClick,
    onEventBankerClick,
    showInputBlock,
    onTraceClick,
    onStakeInput,
    onStakeInputFocus,
    onStakeInputBlur,
    onEventAcceptChanges,
    onAppear,
    onDisappear,
    onBackspaceInput,
    onNumberInput,
    onDecimalInput,
    selectFastBet,
    onEditFastBets,
  };
}
