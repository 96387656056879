import type { ComputedRef, Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import type { VInputInputEvent } from '@leon-hub/input-types';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import { Timer } from '@leon-hub/utils';

import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';

import type { BetSlipSummaryEmits, BetSlipSummaryProps } from '../types';
import { BetSlipSummaryStatus } from '../../../enums';

interface UseBetSlipSummary {
  wrongModeMessage: ComputedRef<string>;
  inputError: ComputedRef<boolean>;
  errorLabel: ComputedRef<string>;
  mustShowBalanceMessage: ComputedRef<boolean>;
  mustShowPrizeMessage: ComputedRef<boolean>;
  isSystemMode: ComputedRef<boolean>;
  canShowFreeBetSelector: ComputedRef<boolean>;
  mustShowMessage: ComputedRef<boolean>;
  computedWarningMessage: ComputedRef<string>;
  showWrongModeMessage: ComputedRef<boolean>;
  freebetTermsRouteParams: ComputedRef<RouteLocationRaw>;
  showAmountValue: ComputedRef<boolean>;
  emitStakeInput(value: string): void;
  emitStakeBlur(value: string): void;
  emitStakeFocus(): void;
  emitFreeBetSwitch(isChecked: boolean): void;
  isPcpSwitchCollapsed: Ref<boolean>;
  isTotalHandicapSwitchCollapsed: Ref<boolean>;
  onAllowPriceChanges(): void;
  onAllowTotalHandicapChanges(): void;
  emitFreebetSelect(event: VInputInputEvent): void;
  emitSystemSelect(event: VInputInputEvent): void;
  emitToggleSameStakeForSingles(): void;
  onFreeBetSwitchClick(): void;
  emitClearClick(): void;
  emitSettingsClick(): void;
}

const collapseAnimationDuration = 200;

export default function useBetSlipSummary(
  props: BetSlipSummaryProps,
  emit: BetSlipSummaryEmits,
): UseBetSlipSummary {
  const { $translate } = useI18n();

  const router = useRouter();

  const formatMoney = useFormatMoney();

  const wrongModeMessage = computed<string>(() => {
    if (!props.wrongBetMode)
      return '';
    switch (props.betMode) {
      case SlipTypeId.SINGLE:
        return $translate('WEB2_SLIP_UNAVAILABLE_SINGLE').value;
      case SlipTypeId.EXPRESS:
        return $translate('WEB2_SLIP_UNAVAILABLE_EXPRESS').value;
      case SlipTypeId.SYSTEM:
        return $translate('WEB2_SLIP_UNAVAILABLE_SYSTEM').value;
      default:
        return '';
    }
  });

  const inputError = computed<boolean>(() => {
    if (!props.readyToDisplayErrors)
      return false;
    switch (props.status) {
      case BetSlipSummaryStatus.NOT_ENOUGH_BALANCE:
      case BetSlipSummaryStatus.ABOVE_MAXIMUM:
      case BetSlipSummaryStatus.BELOW_MINIMUM:
        return true;
      default: return false;
    }
  });

  const outOfLimitsText = computed<string>(() => {
    const from = $translate('WEB2_FROM').value;
    const till = $translate('JSPBET_TILL').value;
    return `${from} ${formatMoney(props.minStake ?? 0)} ${till} ${formatMoney(props.maxStake ?? 0)}`;
  });

  const mustShowBalanceMessage = computed<boolean>(() => props.status === BetSlipSummaryStatus.NOT_ENOUGH_BALANCE);

  const mustShowPrizeMessage = computed<boolean>(() => props.status === BetSlipSummaryStatus.DEFAULT);

  const showWrongModeMessage = computed<boolean>(() => !!props.wrongBetMode && !props.isSyncInProgress);

  const errorLabel = computed<string>(() => {
    if (!inputError.value || !props.readyToDisplayErrors) {
      return '';
    }
    if (mustShowBalanceMessage.value) {
      return $translate('WEB2_STAKE_ABOVE_BALANCE').value;
    }
    return outOfLimitsText.value;
  });

  const isSystemMode = computed<boolean>(() => props.betMode === SlipTypeId.SYSTEM);

  const canShowFreeBetSelector = computed<boolean>(() => !isSystemMode.value
    && !!props.showFreeBet
    && !props.isMultiSingles);

  const mustShowMessage = computed<boolean>(() => {
    switch (props.status) {
      case BetSlipSummaryStatus.DEFAULT:
      case BetSlipSummaryStatus.DONE:
      case BetSlipSummaryStatus.NOT_ENOUGH_BALANCE:
        return false;
      default:
        return true;
    }
  });

  const computedWarningMessage = computed<string>(() => {
    if (props.warningMessage) {
      return props.warningMessage;
    }
    switch (props.status) {
      case BetSlipSummaryStatus.LIMIT:
        return $translate('WEB2_SLIP_SUMMARY_LIMIT_MESSAGE').value;
      case BetSlipSummaryStatus.CHANGED_PRICES_AND_MARKETS:
        return $translate('WEB2_SLIP_WARNING_MARKETS_AND_PRICES_CHANGED').value;
      case BetSlipSummaryStatus.CHANGED_PRICES_AND_ENTRIES:
        return $translate('WEB2_SLIP_WARNING_PRICES_AND_ENTRIES_CHANGED').value;
      case BetSlipSummaryStatus.MARKETS_CHANGED:
        return $translate('WEB2_SLIP_WARNING_MARKETS_CLOSED').value;
      case BetSlipSummaryStatus.PRICES_CHANGED:
        return $translate('WEB2_SLIP_WARNING_PRICES_CHANGED').value;
      case BetSlipSummaryStatus.ENTRIES_CHANGED:
        return $translate('WEB2_SLIP_WARNING_ENTRIES_CHANGED').value;
      case BetSlipSummaryStatus.UNABLE_TO_APPLY_FREEBET:
        return $translate('WEB2_SLIP_WARNING_UNABLE_TO_APPLY_FREEBET', ref({ limit: outOfLimitsText.value })).value;
      case BetSlipSummaryStatus.BELOW_MINIMUM:
        return $translate('WEB2_SLIP_SUMMARY_BELOW_MINIMUM').value;
      case BetSlipSummaryStatus.ABOVE_MAXIMUM:
        return $translate('WEB2_SLIP_SUMMARY_ABOVE_MAXIMUM').value;
      case BetSlipSummaryStatus.WAITING:
        return $translate('WEB2_SLIP_SUMMARY_WAITING').value;
      default:
        return '';
    }
  });

  const freebetTermsRouteParams = computed<RouteLocationRaw>(() => ({
    name: RouteName.CMS_PROMO_TERMS,
    params: {
      cmsKey: props.freeBetTermKey,
    },
  }));

  const showAmountValue = computed<boolean>(() => {
    if (props.isMultiSingles) {
      return !!props.sameStakeForSingles;
    }
    return true;
  });

  const emitStakeFocus = (): void => {
    emit('stake-focus');
  };

  const emitStakeBlur = (value: string): void => {
    emit('stake-blur', value);
  };

  const emitStakeInput = (value: string): void => {
    emit('stake-input', value);
  };

  const isPcpSwitchCollapsed = ref<boolean>(!props.denyPriceChanges);

  const isTotalHandicapSwitchCollapsed = ref<boolean>(!props.denyTotalHandicapChanges);

  watch(() => props.isVisible, () => {
    // need to be here for correct ui
    isPcpSwitchCollapsed.value = !props.denyPriceChanges;
    isTotalHandicapSwitchCollapsed.value = !props.denyTotalHandicapChanges;
  }, { immediate: true });

  const onAllowPriceChanges = (): void => {
    emit('allow-price-changes');
    Timer.setTimeout(() => {
      // smooth animation behaviour
      isPcpSwitchCollapsed.value = true;
    }, collapseAnimationDuration);
  };

  const onAllowTotalHandicapChanges = (): void => {
    emit('allow-total-handicap-changes');
    Timer.setTimeout(() => {
      // smooth animation behaviour
      isTotalHandicapSwitchCollapsed.value = true;
    }, collapseAnimationDuration);
  };

  const emitToggleSameStakeForSingles = (): void => {
    emit('toggle-same-stake-for-singles');
  };

  const emitFreeBetSwitch = (isChecked: boolean): void => {
    emit('freebet-switch', isChecked);
  };

  const emitFreebetSelect = (event: VInputInputEvent): void => {
    emit('freebet-select', event.target.value);
  };

  const emitSystemSelect = (event: VInputInputEvent): void => {
    emit('system-select', event.target.value);
  };

  const onFreeBetSwitchClick = (): void => {
    if (!props.freeBetSwitchDisabled) {
      return;
    }
    switch (props.status) {
      case BetSlipSummaryStatus.CHANGED_PRICES_AND_MARKETS:
      case BetSlipSummaryStatus.MARKETS_CHANGED:
      case BetSlipSummaryStatus.PRICES_CHANGED:
      case BetSlipSummaryStatus.LIMIT:
        return;
      default:
        void router.push(freebetTermsRouteParams.value);
    }
  };

  const emitClearClick = (): void => {
    emit('clear-button-click');
  };

  const emitSettingsClick = (): void => {
    emit('settings-click');
  };

  return {
    wrongModeMessage,
    inputError,
    errorLabel,
    mustShowBalanceMessage,
    mustShowMessage,
    isSystemMode,
    canShowFreeBetSelector,
    mustShowPrizeMessage,
    showAmountValue,
    showWrongModeMessage,
    freebetTermsRouteParams,
    computedWarningMessage,
    isPcpSwitchCollapsed,
    isTotalHandicapSwitchCollapsed,
    emitStakeInput,
    emitStakeFocus,
    emitStakeBlur,
    onAllowPriceChanges,
    onAllowTotalHandicapChanges,
    emitFreeBetSwitch,
    emitToggleSameStakeForSingles,
    emitFreebetSelect,
    emitSystemSelect,
    onFreeBetSwitchClick,
    emitClearClick,
    emitSettingsClick,
  };
}
