<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { AccumulatorBoostInfoProps } from './types';
import useAccumulatorBoostInfo from './composables/useAccumulatorBoostInfo';

const props = withDefaults(defineProps<AccumulatorBoostInfoProps>(), {
  currentMultiplier: 0,
  nextMultiplier: 0,
  maxMultiplier: 0,
  requiredOdds: '',
});
const {
  progress,
  showInfoModal,
  boost,
  termsTranslateParams,
} = useAccumulatorBoostInfo(props);

// need for correct styles
const progressToAlignRight = 84;
</script>

<template>
  <div v-auto-id="'AccumulatorBoostInfo'" :class="$style['accumulator-boost-info']">
    <span :class="$style['accumulator-boost-info__next-boost']">
      {{ currentMultiplier === maxMultiplier
        ? $t('WEB2_ACCUMULATOR_BOOST_INFO_CAPTION_MAX', boost)
        : $t('WEB2_ACCUMULATOR_BOOST_INFO_CAPTION', boost) }}
    </span>
    <span
      v-if="currentMultiplier !== maxMultiplier"
      :class="$style['accumulator-boost-info__terms']"
    >
      {{ $t('WEB2_ACCUMULATOR_BOOST_INFO_TERM', termsTranslateParams) }}
    </span>
    <div :class="$style['accumulator-boost-info__progress']">
      <span :class="$style['accumulator-boost-info__progress-limit']">0%</span>
      <span
        :class="{
          [$style['accumulator-boost-info__progress-bar']]: true,
          [$style['accumulator-boost-info__progress-bar--almost-full']]: progress >= 84,
        }"
        :style="`--progress: ${progress}%`"
      >
        <span
          :class="{
            [$style['accumulator-boost-info__progress-value']]: true,
            [$style['accumulator-boost-info__progress-value--middle']]: progress > 10
              && progress < progressToAlignRight,
            [$style['accumulator-boost-info__progress-value--right']]: progress >= progressToAlignRight,
          }"
          :style="`--progress: ${progress}%`"
        >{{ currentMultiplier }}%</span>
      </span>
      <span :class="$style['accumulator-boost-info__progress-limit']">{{ maxMultiplier }}%</span>
    </div>
    <button
      type="button"
      :class="$style['accumulator-boost-info__help']"
      :title="$t('WEB2_CBC_PROMO_MORE')"
      @click="showInfoModal"
    >
      <VIcon
        :name="IconName.INFO_OUTLINE"
        :size="IconSize.SIZE_16"
      />
    </button>
  </div>
</template>

<style module lang="scss">
.accumulator-boost-info {
  position: relative;
  flex: none;
  padding: 8px 38px 8px 16px;
  overflow: hidden;
  background: var(--OpacityBrandDefault);
  border: solid var(--Layer0);
  border-width: 8px 0;

  &__next-boost {
    @include medium\14\16\025;

    display: block;
    color: var(--BrandHighlight);
  }

  &__terms {
    @include regular\12\16;

    display: block;
    margin: 2px 0 0;
    color: var(--TextSecondary);
  }

  &__progress {
    @include medium\10\12;

    display: flex;
    margin: 8px 0 0;
  }

  &__progress-limit {
    display: block;
    flex: 0;
    color: var(--TextPrimary);
  }

  &__progress-bar {
    position: relative;
    display: block;
    flex: 1;
    height: 12px;
    margin: 0 8px;
    overflow: hidden;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      height: 6px;
      content: '';
      border-radius: $slipBoostProgressBarRadius;
      transform: translateY(-50%);
    }

    &:before {
      right: 0;
      background: var(--OpacityBrandDefault);
    }

    &:after {
      width: calc(var(--progress) + 4px);
      background: var(--BrandDefault);
    }

    &--almost-full:after {
      // to not show behind label
      width: calc(var(--progress) - 10px);
    }
  }

  &__progress-value {
    position: absolute;
    left: var(--progress);
    z-index: 2;
    display: block;
    padding: 0 4px;
    color: var(--BrandText);
    background: var(--BrandDefault);
    border-radius: $slipBoostProgressIndicatorRadius;

    &--middle {
      transform: translateX(-50%);
    }

    &--right {
      transform: translateX(-100%);
    }
  }

  &__help {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 0 0 3px 3px;
    color: var(--TextSecondary);
    cursor: help;
    background: none;
    border: none;
  }
}
</style>
