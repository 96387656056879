<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount } from 'vue';

import { VFastMoneyChoice } from 'web/src/components/FastMoneyChoice';

import BetSlipKeyboard from '../components/BetSlipKeyboard/components/BetSlipKeyboard.vue';
import { BetSlipPlaceBetButton } from '../components/BetSlipPlaceBetButton';
import BetSlipSummary from '../components/BetSlipSummary';
import { useBetSlipFooter } from '../composable';
import { useSlipShare } from '../submodules/slip-share/composables';

const {
  // BetSlipSummary
  betSlipSummaryProps,
  stakeInputFocus,
  stakeInputBlur,
  handleStakeInput,
  selectSystemValue,
  setSelectedFreeBet,
  onChangeFreeBetSwitch,
  allowPriceChanges,
  allowTotalHandicapChanges,
  toggleSameStakeForSingles,
  onClearBetList,
  showSlipSettings,
  // FastMoneyChoice
  isFastBetsEnabled,
  fastBetsProps,
  selectFastBet,
  // common keyboard
  isMobileKeyboardShown,
  hideKeyboard,
  backspace,
  numberInput,
  decimalSeparatorClick,
  editFastBets,
  // PlaceBetButton
  mainButtonProps,
  mainButtonClick,
  // life-cycle related
  onAppear,
  onDisappear,
} = useBetSlipFooter();

const { isCurrentSlipShareEnabled, showSlipShareDialog } = useSlipShare();

onBeforeMount(onAppear);
onBeforeUnmount(onDisappear);
</script>

<template>
  <BetSlipSummary v-auto-id="'BetSlipFooter'"
    v-bind="betSlipSummaryProps"
    :share-enabled="isCurrentSlipShareEnabled"
    @stake-focus="stakeInputFocus"
    @stake-blur="stakeInputBlur"
    @stake-input="handleStakeInput"
    @system-select="selectSystemValue"
    @freebet-select="setSelectedFreeBet"
    @freebet-switch="onChangeFreeBetSwitch"
    @allow-price-changes="allowPriceChanges"
    @allow-total-handicap-changes="allowTotalHandicapChanges"
    @toggle-same-stake-for-singles="toggleSameStakeForSingles"
    @clear-button-click="onClearBetList"
    @settings-click="showSlipSettings"
    @share-slip="showSlipShareDialog"
  >
    <template #fast-bets>
      <VFastMoneyChoice
        v-if="isFastBetsEnabled"
        v-bind="fastBetsProps"
        @select-value="selectFastBet"
        @edit-click="editFastBets"
      />
    </template>
    <template
      v-if="null"
      #keyboard
    >
      <BetSlipKeyboard
        v-if="isMobileKeyboardShown"
        @hide-keyboard="hideKeyboard"
        @backspace="backspace"
        @number-input="numberInput"
        @decimal="decimalSeparatorClick"
      />
    </template>
    <template #button>
      <BetSlipPlaceBetButton
        v-data-test="{
          el: 'bet-slip-button_summary',
          mode: mainButtonProps.mode,
        }"
        v-bind="mainButtonProps"
        @click="mainButtonClick"
      />
    </template>
  </BetSlipSummary>
</template>
