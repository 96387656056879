<script setup lang="ts">
import { defineAsyncComponent, onBeforeUnmount } from 'vue';

import { useTheme } from '@core/theme';

import { VLoaderMargin } from '@components/loader';

import { VEmpty } from 'web/src/components/Empty';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { LazyMainBannerSectionRouteComponent as MainBannerSectionRouteComponent } from 'web/src/modules/banners/components/MainBannerSectionRouteComponent';
import { BannerLocation } from 'web/src/modules/banners/enums';
import { CashoutView } from 'web/src/modules/cashout/views';
import { ModalSelector } from 'web/src/modules/core/enums';

import AccumulatorBoostInfo from '../components/AccumulatorBoostInfo';
import BetSlipBookingCodeForm from '../components/BetSlipBookingCodeForm';
import { BetSlipConfirmClearOverlay } from '../components/BetSlipConfirmClearOverlay';
import BetSlipEmptyPlaceholder from '../components/BetSlipEmptyPlaceholder';
import BetSlipMain from '../components/BetSlipMain';
import BetSlipResult from '../components/BetSlipResult';
import BookingCodeLoadResult from '../components/BookingCodeLoadResult';
import BetSlipContainer from '../components/SlipContainer';
import SlipLayout from '../components/SlipLayout';
import WinBoostInfo from '../components/WinBoostInfo';
import {
  useAccumulatorBoost,
  useBetSlipRoot,
  useSlipTrace,
  useSlipWarningDialog,
} from '../composable';
import { useGetSharedSlip } from '../submodules/get-shared-slip/composables';
import { useSlipShare } from '../submodules/slip-share/composables';
import BetSlipFooter from './BetSlipFooter.vue';
import BetSlipMyBetsView from './BetSlipMyBetsView.vue';

const BetSlipTraceModal = '1'
  ? defineAsyncComponent(() => import('../components/BetSlipTrace'))
  : defineAsyncComponent(() => Promise.resolve(VEmpty));

const SlipSettingsView = defineAsyncComponent(() => import('./BetSlipSettings.vue'));

const {
  // BetSlipContainer related
  containerProps,
  onDisplayAnimationEnd,
  onManualClose,
  // BetSlipView related
  slipLayoutProps,
  isClearBetListWarnVisible,
  selectTopTab,
  onClearBetsCancel,
  onClearBetsConfirm,
  handleInnerScroll,
  // loader and empty placeholder
  isRestoringSlipFromLS,
  isEmpty,
  isEmptySlipButtonHidden,
  emptySlipButtonLabel,
  emptySlipButtonClick,
  // BetSlipMain
  betSlipMainProps,
  onSelectBetMode,
  onEventDeleteClick,
  onBankerClick,
  onSingleStakeInput,
  onMultiSinglesInputFocus,
  onMultiSinglesInputBlur,
  onAcceptSinglePriceChanges,
  editFastBets,
  handleSinglesMobileInput,
  // SlipResult
  resultViewProps,
  switchToMyBets,
  handleSlipResultLeave,
  handleLimitsClick,
  resetResultErrorState,
  allowTotalHandicapChanges,
  // life-cycle related
  onDisappear,
} = useBetSlipRoot();

useSlipWarningDialog();

const {
  isAccumulatorBoostApplicable,
  currentMultiplier,
  nextMultiplier,
  maxMultiplier,
  requiredOdds,
  itemsToNextBoost,
  isWinBoost,
  winBoostValue,
} = useAccumulatorBoost();

const {
  isTraceModalVisible,
  currentTraceLines,
  showTraceButton,
  showTraceModal,
  hideTraceModal,
} = useSlipTrace();

const {
  isCustomerBetShareEnabled,
  shareLastBet,
} = useSlipShare();

const {
  isReceiveFromCodeEnabled,
  isWaitingToSlipFromCode,
  bookingCodeError,
  isSlipFromBookingCodeOverlayVisible,
  isAllSharedEventsExpired,
  isLoggedIn,
  sharedBookingCode,
  isBookingCodeFromLink,
  getSlipFromBookingCode,
  onBookingCodeInput,
  onOverlayButtonClick,
  resetBookingCodeForm,
} = useGetSharedSlip();

const { isLight } = useTheme();

onBeforeUnmount(onDisappear);
</script>

<template>
  <BetSlipContainer v-auto-id="'TheBetsSlipRootView'"
    v-bind="containerProps"
    @display-animation-end="onDisplayAnimationEnd"
    @handle-close="onManualClose"
  >
    <SlipLayout
      v-bind="slipLayoutProps"
      :show-booking-overlay="isSlipFromBookingCodeOverlayVisible"
      @select-top-tab="selectTopTab"
      @scroll="handleInnerScroll"
    >
      <template #main>
        <VLoaderDelayed
          v-if="isRestoringSlipFromLS"
          :margin="VLoaderMargin.MEDIUM"
        />
        <BetSlipEmptyPlaceholder
          v-else-if="isEmpty"
          :hide-button="isEmptySlipButtonHidden"
          :have-button-in-slot="isReceiveFromCodeEnabled"
          :button-label="emptySlipButtonLabel"
          @button-click="emptySlipButtonClick"
        >
          <template v-if="isReceiveFromCodeEnabled">
            <BetSlipBookingCodeForm
              :is-pending="isWaitingToSlipFromCode"
              :error="bookingCodeError"
              :value="sharedBookingCode"
              @submit-code="getSlipFromBookingCode"
              @input-code="onBookingCodeInput"
            />
          </template>
        </BetSlipEmptyPlaceholder>
        <template v-else>
          <BetSlipMain
            v-bind="betSlipMainProps"
            :show-trace-button="showTraceButton"
            @select-bet-mode="onSelectBetMode"
            @event-delete-click="onEventDeleteClick"
            @event-banker-click="onBankerClick"
            @stake-input="onSingleStakeInput"
            @stake-input-focus="onMultiSinglesInputFocus"
            @stake-input-blur="onMultiSinglesInputBlur"
            @event-accept-changes="onAcceptSinglePriceChanges"
            @trace-click="showTraceModal"
            @edit-fast-bets="editFastBets"
            @singles-mobile-input="handleSinglesMobileInput"
          />
          <template v-if="isAccumulatorBoostApplicable">
            <WinBoostInfo
              v-if="isWinBoost"
              :required-odds="requiredOdds"
              :current-boost="currentMultiplier"
              :next-boost="nextMultiplier"
              :items-to-next-boost="itemsToNextBoost"
              :current-win-bonus="winBoostValue ?? undefined"
            />
            <AccumulatorBoostInfo
              v-else
              :current-multiplier="currentMultiplier"
              :next-multiplier="nextMultiplier"
              :max-multiplier="maxMultiplier"
              :required-odds="requiredOdds"
              :items-to-next-boost="itemsToNextBoost"
            />
          </template>
          <BetSlipFooter />
        </template>
      </template>
      <template #my-bets>
        <BetSlipMyBetsView />
      </template>
      <template #settings>
        <SlipSettingsView @scroll="handleInnerScroll" />
      </template>
      <template #result>
        <BookingCodeLoadResult
          v-if="isSlipFromBookingCodeOverlayVisible"
          :is-all-events-expired="isAllSharedEventsExpired"
          :is-light-theme="isLight"
          :is-logged-in="isLoggedIn"
          :is-from-link="isBookingCodeFromLink"
          @vue:before-unmount="resetBookingCodeForm"
          @button-click="onOverlayButtonClick"
        />
        <BetSlipResult
          v-else
          v-bind="resultViewProps"
          :is-share-available="isCustomerBetShareEnabled"
          @go-to-my-bets="switchToMyBets"
          @leave-click="handleSlipResultLeave"
          @limits-click="handleLimitsClick"
          @close-on-error="resetResultErrorState"
          @share-bet="shareLastBet"
          @accept-total-handicap-changes="allowTotalHandicapChanges"
        />
      </template>
      <template #confirm-overlay>
        <BetSlipConfirmClearOverlay
          v-if="isClearBetListWarnVisible"
          data-test-id="confirm-overlay"
          @confirm="onClearBetsConfirm"
          @cancel="onClearBetsCancel"
        />
      </template>
      <template
        v-if="'1'"
        #banners
      >
        <MainBannerSectionRouteComponent
          is-static
          :location="BannerLocation.RIGHT"
          :banner-type="BannerType.ASIDE_BANNER"
        />
      </template>
      <template #cashout>
        <CashoutView />
      </template>
    </SlipLayout>
    <template v-if="'1'">
      <ModalPortal
        v-if="isTraceModalVisible"
        :selector="ModalSelector.BODY"
      >
        <BetSlipTraceModal
          :lines="currentTraceLines"
          @close-modal="hideTraceModal"
        />
      </ModalPortal>
    </template>
  </BetSlipContainer>
</template>
