import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { BetSlipResultEmits, BetSlipResultProps } from '../types';
import { BetSlipResultState, LeaveSlipResultChoice } from '../../../enums';

interface UseBetSlipResult {
  isContentVisible: ComputedRef<boolean>;
  isPartiallyAccepted: ComputedRef<boolean>;
  iconName: ComputedRef<IconNameType | undefined>;
  headingText: ComputedRef<string>;
  descriptionText: ComputedRef<string>;
  isCompleted: ComputedRef<boolean>;
  isSuccess: ComputedRef<boolean>;
  isLimitsButtonShown: ComputedRef<boolean>;
  isCloseAndClearButtonShown: ComputedRef<boolean>;
  isPartiallyAcceptedByLimits: ComputedRef<boolean>;
  shareButtonVisible: ComputedRef<boolean>;
  mainButtonLabel: ComputedRef<string>;
  mainButtonIcon: ComputedRef<IconNameType | undefined>;
  goToMyBets(): void;
  onMainButtonClick(): void;
  onSaveAllClick(): void;
  onCloseAndClearClick(): void;
  onLimitsClick(): void;
  onShareClick(): void;
  onAcceptTotalHandicapChanges(): void;
}

export default function useBetSlipResult(
  props: BetSlipResultProps,
  emit: BetSlipResultEmits,
): UseBetSlipResult {
  const { $translate } = useI18n();

  const isContentVisible = computed<boolean>(
    () => props.mode !== BetSlipResultState.INITIAL && props.mode !== BetSlipResultState.PENDING,
  );

  const isPartiallyAccepted = computed<boolean>(
    () => !!(props.isMultiSinglesMode && ((props.totalBetsPlaced ?? 0) > (props.totalBetsAccepted ?? 0))),
  );

  const isError = computed<boolean>(() => props.mode === BetSlipResultState.ERROR);

  const isSuccess = computed<boolean>(() => props.mode === BetSlipResultState.SUCCESS);

  const isCompleted = computed<boolean>(() => isError.value || isSuccess.value);

  /** caught limits exception on multisingles */
  const isPartiallyAcceptedByLimits = computed<boolean>(
    () => !!(props.isMultiSinglesMode && props.limitsExceeded && isSuccess.value),
  );

  const iconName = computed<IconNameType | undefined>(() => {
    switch (props.mode) {
      case BetSlipResultState.SUCCESS:
        return isPartiallyAccepted.value ? IconName.CHECKBOX_MULTIPLE : IconName.CHECK_OUTLINE;
      case BetSlipResultState.ERROR:
        return IconName.ATTENTION_OUTLINE;
      default:
        return undefined;
    }
  });

  const multiSuccessTranslateParams = computed<Record<string, string>>(() => ({
    placed: `${props.totalBetsPlaced ?? 0}`,
    accepted: `${props.totalBetsAccepted ?? 0}`,
  }));

  const headingText = computed<string>(() => {
    switch (props.mode) {
      case BetSlipResultState.SUCCESS:
        return props.isMultiSinglesMode
          ? $translate('WEB2_PLACE_BET_MULTIPLE_RESULT', multiSuccessTranslateParams).value
          : $translate('JS_SLIP_BET_ACCEPTED').value;
      case BetSlipResultState.ERROR:
        return props.isMultiSinglesMode
          ? $translate('WEB2_PLACE_BET_MULTIPLE_ERROR_CAPTION').value
          : $translate('WEB2_PLACE_BET_ERROR_CAPTION').value;
      default:
        return '';
    }
  });

  const descriptionText = computed<string>(() => {
    const defaultErrorMessage = props.isMultiSinglesMode
      ? $translate('WEB2_PLACE_BET_MULTIPLE_ERROR_DESCRIPTION').value
      : $translate('WEB2_PLACE_BET_ERROR_DESCRIPTION').value;
    if (isPartiallyAcceptedByLimits.value) {
      return $translate('WEB2_PLACE_BET_MULTIPLE_BET_LIMITS').value;
    }
    switch (props.mode) {
      case BetSlipResultState.SUCCESS:
        return props.isMultiSinglesMode
          ? $translate('WEB2_PLACE_BET_OK_DESCRIPTION_MULTIPLE').value
          : $translate('WEB2_PLACE_BET_OK_DESCRIPTION').value;
      case BetSlipResultState.ERROR:
        if (props.isTotalHandicapChanged) {
          return $translate('WEB2_SLIP_TOTAL_HANDICAP_HAS_CHANGED').value;
        }
        return props.errorMessage || defaultErrorMessage;
      default:
        return '';
    }
  });

  const isLimitsButtonShown = computed<boolean>(
    () => !!(props.limitsExceeded && isError.value),
  );

  const isCloseAndClearButtonShown = computed<boolean>(() => {
    if (isPartiallyAcceptedByLimits.value) {
      return true;
    }
    return !!(props.isMultiSinglesMode && isPartiallyAccepted.value && isSuccess.value);
  });

  const mainButtonLabel = computed<string>(() => {
    if (isSuccess.value) {
      return isPartiallyAccepted.value
        ? $translate('WEB2_SLIP_SAVE_BETS_UNACCEPTED').value
        : $translate('WEB2_DONE').value;
    }
    return $translate('WEB2_PLACE_BET_BUTTON_ERROR').value;
  });

  const mainButtonIcon = computed<IconNameType | undefined>(() => {
    if (isSuccess.value && !isPartiallyAccepted.value) {
      return IconName.CHECK_FILLED;
    }
    return undefined;
  });

  const shareButtonVisible = computed<boolean>(() => {
    if (!props.isShareAvailable) {
      return false;
    }
    return isSuccess.value && !props.limitsExceeded;
  });

  const goToMyBets = (): void => {
    emit('go-to-my-bets');
  };

  const emitLeaveClick = (choice: LeaveSlipResultChoice): void => {
    emit('leave-click', choice);
  };

  const emitCloseOnError = (): void => {
    emit('close-on-error');
  };

  const onMainButtonClick = (): void => {
    if (isError.value) {
      emitCloseOnError();
      return;
    }
    emitLeaveClick(isPartiallyAccepted.value
      ? LeaveSlipResultChoice.SAVE_UNACCEPTED
      : LeaveSlipResultChoice.CLEAR_ALL);
  };

  const onSaveAllClick = (): void => {
    emitLeaveClick(LeaveSlipResultChoice.SAVE_ALL);
  };

  const onCloseAndClearClick = (): void => {
    emitLeaveClick(LeaveSlipResultChoice.CLEAR_ALL);
  };

  const onLimitsClick = (): void => {
    emit('limits-click');
  };

  const onShareClick = (): void => {
    emit('share-bet');
  };

  const onAcceptTotalHandicapChanges = (): void => {
    emit('accept-total-handicap-changes');
    emitCloseOnError();
  };

  return {
    isContentVisible,
    isPartiallyAccepted,
    iconName,
    headingText,
    descriptionText,
    isCompleted,
    isSuccess,
    isLimitsButtonShown,
    mainButtonLabel,
    mainButtonIcon,
    isPartiallyAcceptedByLimits,
    isCloseAndClearButtonShown,
    shareButtonVisible,
    goToMyBets,
    onMainButtonClick,
    onSaveAllClick,
    onCloseAndClearClick,
    onLimitsClick,
    onShareClick,
    onAcceptTotalHandicapChanges,
  };
}
