<script setup lang="ts">
import type { BetSlipTabsEmits, BetSlipTabsProps } from './types';
import { TopLevelTabs } from '../../enums';
import useBetSlipTabs from './composables/useBetSlipTabs';

const props = withDefaults(defineProps<BetSlipTabsProps>(), {
  activeTabId: TopLevelTabs.SLIP,
  slipSize: 0,
  myBetsCount: 0,
});

defineEmits<BetSlipTabsEmits>();

const { tabsItems } = useBetSlipTabs(props);
</script>

<template>
  <div v-auto-id="'BetSlipTabs'" :class="$style['bet-slip-tabs']">
    <ul :class="$style['bet-slip-tabs__list']">
      <li
        v-for="{ id, label, count, isActive } in tabsItems"
        :key="id"
        :class="$style['bet-slip-tabs__item']"
      >
        <button
          :class="{
            [$style['bet-slip-tabs__button']]: true,
            [$style['bet-slip-tabs__button--active']]: isActive,
          }"
          :data-test-id="`tab-${id}`"
          type="button"
          @click="$emit('tab-click', id)"
        >
          {{ label }}
          <span
            :class="{
              [$style['bet-slip-tabs__count']]: true,
              [$style['bet-slip-tabs__count--active']]: isActive,
            }"
          >
            {{ count }}
          </span>
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" module>
.bet-slip-tabs {
  display: flex;
  flex-shrink: 0;
  padding: 4px 4px 0;
  margin: 0;
  overflow: hidden;

  @include for-hover {
    &__button:hover &__count {
      background-color: var(--TextDefault);
    }
  }

  &__list {
    display: flex;
    flex: 1;
    padding: 2px;
    margin: 0;
    overflow: hidden;
    list-style: none;
    background-color: var(--Layer2);
    border-radius: if($isEnvFeatureTwinlikeStyle, 10px, 7px);
  }

  &__item {
    position: relative;
    display: flex;
    flex: 1 1 50%;
    height: 40px;

    &:only-child {
      flex-basis: 100%;
    }
  }

  &__button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    color: var(--TextSecondary);
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    background: none;
    border: none;
    border-radius: if($isEnvFeatureTwinlikeStyle, 8px, 5px);

    @include for-hover {
      &:hover {
        color: var(--TextDefault);
      }
    }

    &--active {
      color: var(--TextDefault);
      cursor: default;
      background-color: var(--Highlight);
    }
  }

  &__count {
    @include bold\12\16\-07;

    min-width: 16px;
    padding: 0 4px;
    margin-left: 4px;
    color: var(--Layer1);
    text-align: center;
    letter-spacing: normal;
    background-color: var(--TextSecondary);
    border-radius: 8px;

    &--active {
      color: var(--Highlight);
      background-color: var(--TextDefault);
    }
  }
}
</style>
