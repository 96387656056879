import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { IPriceChangePolicy } from '@leon-hub/api-sdk';
import { priceChangePolicyAllowChanges, priceChangePolicyAllowIncrease, priceChangePolicyDenyChanges } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';

import type { SlipCommonSettingsEmits, SlipCommonSettingsProps } from '../types';

interface UseSlipCommonSettings {
  autoUpdateEnabled: ComputedRef<boolean>;
  /** not a getter only to not become unchecked while collapse animation playing */
  onlyIncrease: Ref<boolean>;
  acceptsTotalHandicapChanges: ComputedRef<boolean>;
  onAutoUpdateChange(value: boolean): void;
  onTotalHandicapSwitch(value: boolean): void;
  toggleOnlyIncrease(): void;
  resetOnlyIncreaseValue(): void;
}

export default function useSlipCommonSettings(
  props: SlipCommonSettingsProps,
  emit: SlipCommonSettingsEmits,
): UseSlipCommonSettings {
  const analytics = useAnalytics();
  const autoUpdateEnabled = computed<boolean>(() => props.priceChangePolicy !== priceChangePolicyDenyChanges);

  const onlyIncrease = ref<boolean>(props.priceChangePolicy === priceChangePolicyAllowIncrease);

  const acceptsTotalHandicapChanges = computed<boolean>(() => props.totalHandicapPriceChangePolicy === priceChangePolicyAllowChanges);

  const emitChangePolicySelect = (value: IPriceChangePolicy): void => {
    emit('change-policy-select', value);
  };

  const emitTotalHandicapPolicySelect = (value: IPriceChangePolicy): void => {
    emit('total-handicap-policy-select', value);
  };

  const onAutoUpdateChange = (value: boolean): void => {
    const newValue = autoUpdateEnabled.value ? priceChangePolicyDenyChanges : priceChangePolicyAllowChanges;
    emitChangePolicySelect(newValue);
    analytics.push(AnalyticsEvent.CLICK_MAP, {
      clickCounter: {
        autoUpdateCoeff: value ? 'turnOn' : 'turnOff',
      },
    });
  };

  const toggleOnlyIncrease = (): void => {
    const newValue = onlyIncrease.value ? priceChangePolicyAllowChanges : priceChangePolicyAllowIncrease;
    onlyIncrease.value = !onlyIncrease.value;
    emitChangePolicySelect(newValue);
  };

  const onTotalHandicapSwitch = (value: boolean): void => {
    emitTotalHandicapPolicySelect(value ? priceChangePolicyAllowChanges : priceChangePolicyDenyChanges);
  };

  const resetOnlyIncreaseValue = (): void => {
    if (!autoUpdateEnabled.value) {
      onlyIncrease.value = false;
    }
  };

  return {
    autoUpdateEnabled,
    onlyIncrease,
    acceptsTotalHandicapChanges,
    onAutoUpdateChange,
    toggleOnlyIncrease,
    resetOnlyIncreaseValue,
    onTotalHandicapSwitch,
  };
}
