<script setup lang="ts">
import BetSlipEditFastBet from '../components/BetSlipEditFastBet';
import BetSlipSettingsView from '../components/BetSlipSettingsView';
import SlipCommonSettings from '../components/SlipCommonSettings';
import { useBetSlipSettings } from '../composable';
import { SettingsTabs } from '../enums';

interface Emits {
  (e: 'scroll'): void;
}

defineEmits<Emits>();

const {
  activeSettingsTabId,
  priceChangePolicy,
  totalHandicapPriceChangePolicy,
  showSlipOnFirstAdded,
  totalHandicapReplaceEnabled,
  changePriceChangePolicy,
  changeTotalHandicapPolicy,
  toggleShowSlipOnFirstAdded,
  minFastBet,
  maxFastBet,
  maxEnabled,
  changesIsSaved,
  fastBets,
  useStandardBet,
  minStandardBet,
  maxStandardBet,
  standardBetAmount,
  standardBetAmountIsSaved,
  standardBetInputErrorKind,
  initFastBetInputValues,
  onBastBetSettingsLeave,
  handleFastBetValuesBlur,
  handleFastBetValuesChange,
  onStandardBetFocus,
  handleFastBetMaxSwitch,
  toggleUseStandardBet,
  onStandardBetInput,
  onStandardBetBlur,
  selectSettingsTab,
  onSettingsBackClick,
} = useBetSlipSettings();
</script>

<template>
  <BetSlipSettingsView v-auto-id="'BetSlipSettings'"
    :active-settings-tab-id="activeSettingsTabId"
    @select-settings-tab="selectSettingsTab"
    @settings-back-click="onSettingsBackClick"
    @scroll="$emit('scroll')"
  >
    <template v-if="activeSettingsTabId === SettingsTabs.COMMON_SETTINGS">
      <SlipCommonSettings
        :price-change-policy="priceChangePolicy"
        :show-slip-on-first-added="showSlipOnFirstAdded"
        :total-handicap-price-change-policy="totalHandicapPriceChangePolicy"
        :total-handicap-replace-enabled="totalHandicapReplaceEnabled"
        @change-policy-select="changePriceChangePolicy"
        @toggle-show-slip-on-first-added="toggleShowSlipOnFirstAdded"
        @total-handicap-policy-select="changeTotalHandicapPolicy"
      />
    </template>
    <template v-else>
      <BetSlipEditFastBet
        :max-enabled="maxEnabled"
        :changes-is-saved="changesIsSaved"
        :fast-bets="fastBets"
        :min-fast-bet="minFastBet"
        :max-fast-bet="maxFastBet ?? undefined"
        :use-standard-bet="useStandardBet"
        :max-standard-bet="maxStandardBet"
        :min-standard-bet="minStandardBet"
        :standard-bet-amount="standardBetAmount ?? undefined"
        :standard-bet-amount-is-saved="standardBetAmountIsSaved"
        :standard-bet-input-error-kind="standardBetInputErrorKind"
        @vue:mounted="initFastBetInputValues"
        @vue:before-unmount="onBastBetSettingsLeave"
        @fast-bets-submit="handleFastBetValuesBlur"
        @fast-bets-blur="handleFastBetValuesBlur"
        @fast-bets-input="handleFastBetValuesChange"
        @switch-max-enabled="handleFastBetMaxSwitch"
        @toggle-use-standard-bet="toggleUseStandardBet"
        @standard-sum-focus="onStandardBetFocus"
        @standard-sum-input="onStandardBetInput"
        @standard-sum-blur="onStandardBetBlur"
      />
    </template>
  </BetSlipSettingsView>
</template>
